<template>
  <CheckoutLayout :step="3">
    <div class="row">
      <div class="col-12 col-xxl-8">
        <div class="checkout__bank-transfer">
          <h2 class="text text--xs-mt-0 text--xs-header-3 text--lg-header-2">
            Zamównie zostało przyjęte do realizacji
          </h2>

          <p>
            Jesteśmy w trakcie weryfikacji twojego zamówienia VIP. Powiadomimy
            Cię o akceptacji po zakończeniu procesu weryfikacji.
          </p>

          <p class="checkout__text">
            W razie problemów skontaktuj się z nami:
            <a :href="mailto" class="checkout__email">
              biuro@fotoregiony.pl
            </a>
          </p>
        </div>
      </div>
    </div>
  </CheckoutLayout>
</template>

<script>
import CheckoutLayout from '../checkout-layout/checkout-layout.vue';

export default {
  name: 'PaymentBankTransfer',
  components: {
    CheckoutLayout,
  },
  props: {
    orderId: {
      required: true,
      type: String,
    },
  },
  metaInfo: {
    title: 'Szczegóły konta bankowego',
  },
  computed: {
    mailto() {
      return `mailto:biuro@fotoregiony.pl?subject=Problem z zamówieniem nr: ${this.orderId}`;
    },
  },
};
</script>
